import validate from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/pages/runtime/validate.js";
import company_45global from "/home/runner/work/ticketera-admin/ticketera-admin/middleware/company.global.ts";
import manifest_45route_45rule from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  company_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/home/runner/work/ticketera-admin/ticketera-admin/middleware/authenticated.ts"),
  guest: () => import("/home/runner/work/ticketera-admin/ticketera-admin/middleware/guest.ts")
}