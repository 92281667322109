import revive_payload_client_4sVQNw7RlN from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ticketera-admin/ticketera-admin/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/home/runner/work/ticketera-admin/ticketera-admin/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/home/runner/work/ticketera-admin/ticketera-admin/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import api_GFfDXud5Cr from "/home/runner/work/ticketera-admin/ticketera-admin/plugins/api.ts";
import auth_vT9JWWT9pN from "/home/runner/work/ticketera-admin/ticketera-admin/plugins/auth.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  api_GFfDXud5Cr,
  auth_vT9JWWT9pN
]